import * as Toast from 'bootstrap/js/dist/toast';
// import { Toast } from 'bootstrap/dist/js/bootstrap.esm';

export default class TickMessage {
  /**
   * Constructor for the Quickform class
   */
  constructor() {
    this.events = {
      triggerTick: null,
    };

    // bind & inits
    this.mount();
  }

  /**
   * binding & inits
   */
  mount() {
    console.log('mount tick');

    // add tick event to document
    document.addEventListener('triggerTick', this.events.triggerTick);
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount');

    // remove tick event
    document.removeEventListener('triggerTick', this.events.triggerTick);
  }

  /**
   * trigger tick message
   */
  // eslint-disable-next-line class-methods-use-this
  sendMessage(message, type = 'success') {
    // init
    let fill = '#00b22c';

    // check type
    switch (type) {
      case 'info':
        fill = '#1da1f2';
        break;
      case 'error':
        fill = '#d4081a';
        break;
      default:
        fill = '#00b22c';
        break;
    }

    const toastLive = document.getElementById('liveToast');

    toastLive.querySelector('rect')
      .setAttribute('fill', fill);
    toastLive.querySelector('.toast-body').innerHTML = message;
    const toast = new Toast(toastLive);
    toast.show();
  }
}
