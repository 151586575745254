import Alert from '@/js/components/alert';
import {getTranslation} from '@/utils/languages';
import axios from 'axios';
import {triggerNotification} from '@/utils/notifications';
import {imgRoot, langRoot} from '@/utils/paths';

export default class XhrExe {
  /**
   * Constructor for the Xhr exe class
   */
  constructor(htmlEl) {
    this.dom = {
      htmlEl,
    };

    this.props = {
      handle: null,
      key: null,
      csrf: null,
      confirmAlert: false,
      doHandleAfterClick: false,
    };

    this.events = {
      onClick: this.onClick.bind(this),
      afterClick: this.afterClick.bind(this),
    };

    // bind & inits
    this.mount();
  }

  /**
   * binding & inits
   */
  mount() {
    console.log('mount xhr exe...');

    // set the handle
    this.props.handle = this.dom.htmlEl.dataset?.handle;
    this.props.token = this.dom.htmlEl.dataset?.token;
    this.props.key = this.dom.htmlEl.dataset?.key;
    if (this.dom.htmlEl.dataset.confirmAlert)
      this.props.confirmAlert = true;
    if (this.dom.htmlEl.dataset.after)
      this.props.doHandleAfterClick = true;

    // add click event
    this.dom.htmlEl.addEventListener('click', this.events.onClick);
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount xhr exe...');

    // remove submit event
    this.dom.htmlEl.removeEventListener('click', this.events.onClick);
  }

  /**
   * manage the callback after click
   * @param e {CustomEvent}
   */
  async onClick(e) {
    console.log('xhr htmlEl clicked');

    // prevent default action
    e.preventDefault();

    if (this.props.confirmAlert) {
      const confirmAlert = new Alert(
        {
          text: getTranslation('are you sure'),
          title: getTranslation('attention'),
          showConfirmButton: true,
          confirmButtonText: '<i class="uil uil-thumbs-up"></i> ' + getTranslation('yes'),
          showCancelButton: true,
          cancelButtonText: '<i class="uil uil-ban"></i> ' + getTranslation('no'),
          customClass: {
            confirmButton: 'btn btn-primary mx-1',
            cancelButton: 'btn btn-danger mx-1',
          },
        },
        (result) => {
          if (result.isConfirmed) {
            // execute on confirm
            this.executeAction();

            // optional after click action
            if (this.props.doHandleAfterClick) this.afterClick();
          }
        },
      );
      confirmAlert.show();
    } else {
      // default exe without confirmation
      this.executeAction();

      // optional after click action
      if (this.props.doHandleAfterClick) this.afterClick();
    }
  }

  /**
   * execute the action
   */
  executeAction() {
    // form data
    const formData = new FormData();
    formData.append('handle', this.props.handle);
    formData.append('csrf', this.props.token);
    if (this.props.key) formData.append('key', this.props.key);

    // make axios call
    axios({
      method: 'post',
      url: langRoot + this.props.handle,
      data: formData,
    })
      .then((response) => {
        if (response.data.success) {
          if (response.data.redirect) {
            if (response.data.successMessage) {
              triggerNotification(response.data.successMessage);
            }
            setTimeout(() => {
              window.location.href = response.data.redirect;
            }, "1500");
          } else if (response.data.successMessage) {
            triggerNotification(response.data.successMessage);
          }
          // reset the form if requested
          if (response.data.reset) {
            this.resetForm();
          }
        } else {
          triggerNotification(response.data.errorMessage, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        triggerNotification('Something went wrong, please contact naughty and wild?', 'error');
      });
  }

  async afterClick() {
    const pieces = this.props.handle.split('/');
    switch (pieces.slice(-1)[0]) {
      case 'reset-profile-picture':
        this.resetProfilePicture();
        break;
      default:
        console.log('method after click was not defined');
        break;
    }
  }

  // clean up the interface live
  resetProfilePicture() {
    document.getElementById('profile-picture').src = `${imgRoot}noprofile.svg`;
    document.getElementById('profile-picture-preview').src = `${imgRoot}noprofile.svg`;
  }

}
