export function scrollFunction() {
  const mybutton = document.getElementById('back-to-top');
  if (mybutton != null) {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      mybutton.style.display = 'block';
    } else {
      mybutton.style.display = 'none';
    }
  }
}

// Menu
// Menu sticky
export function windowScroll() {
  const navbar = document.getElementById('topnav');
  if (navbar != null) {
    if (
      document.body.scrollTop >= 50
      || document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }
}

// Toggle menu
export function toggleMenu() {
  document.getElementById('isToggle').classList.toggle('open');
  const isOpen = document.getElementById('navigation');
  if (isOpen.style.display === 'block') {
    isOpen.style.display = 'none';
  } else {
    isOpen.style.display = 'block';
  }
}

// go to top
export function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export function setLineWidth(text, item) {
  const lineWidth = `${text.offsetWidth}px`;
  item.style.setProperty('--lineWidth', lineWidth);
}

export function handleTransition(item, text) {
  item.addEventListener('transitionend', (e) => {
    if (e.propertyName != 'flex-grow'
      || !item.classList.contains('active')) return;

    text.classList.add('active');
  });
}

export default {
  scrollFunction,
  windowScroll,
  topFunction,
  toggleMenu,
  setLineWidth,
  handleTransition,
};
