import QuickForm from './quickform';

class ProjectForms {
  constructor() {
    this.dom = {
      qForms: [],
      masterSlaves: [],
    };
    // mount class
    this.mount();
  }

  mount() {
    console.log('mount ProjectForms class...');
    this.dom.qForms = document.querySelectorAll('.quick-form');
    // make quick forms objects if any
    if (this.dom.qForms.length > 0) {
      this.dom.qForms.forEach((formEl) => new QuickForm(formEl));
    }
  }
}

// instantiate if relevant
// eslint-disable-next-line no-new
new ProjectForms();
