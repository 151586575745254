/**
 * Javascript language declarations for mendeleevApp
 * JS library for all modules - common use
 *
 * Version 1.0
 */

import en from '@/locales/en';
import nl from '@/locales/nl';

/* defined translations languages */
export const definedLanguages = ['nl', 'en'];

/**
 * Get language code
 * @returns {string}
 */
export function getLanguageCode() {
  let lang = document.body.dataset.userlang;

  /* Set lang to nl if unknown language */
  if (!definedLanguages.includes(lang)) {
    lang = 'nl';
  }
  return lang;
}

/**
 * Translate text
 * @param str {string} - Text to translate
 * @param lang {string} - in desired language
 * @returns {string}
 */
export function getTranslation(str, lang = getLanguageCode()) {
  switch (lang) {
    case 'nl':
      return nl[str];
    default:
      return en[str];
  }
}

export default {
  definedLanguages,
  getLanguageCode,
  getTranslation,
};
