/* eslint-disable no-unused-vars */
import '@/utils/cookie-functions';
import '@/utils/profile-menu-functions';
import '@/js/engine/pageutils';
import '@/js/engine/project-forms';
import '@/js/engine/project-xhr-functions';
import '@/js/components/masterslave';
// pages
import '@/js/components/profile-picture';
import '@/js/pages/home';
import '@/js/pages/account-settings';

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
