import TickMessage from '@/js/components/tickmessage';

/**
 * trigger tick message
 */
// eslint-disable-next-line class-methods-use-this
export function triggerNotification(message, type = 'success') {
  // create new ticker message
  const notification = new TickMessage();
  notification.sendMessage(message, type);
}

export default {
  triggerNotification,
};
