import Swal from 'sweetalert2';
import { getTranslation } from '@/utils/languages';

export default class Alert {
  /**
   * @param opts {object}
   * @param cb {function}
   */
  constructor(opts = {}, cb = null) {
    this.defaults = {
      title: 'Whoops...',
      text: getTranslation('contact your webpartner'),
      icon: 'error',
      showCancelButton: false,
      buttonsStyling: false,
      confirmButtonText: 'Ok',
      customClass: {
        title: 'mb-3',
        content: 'lead text-muted mb-3',
        confirmButton: 'btn btn-lg btn-danger',
      },
    };
    this.options = { ...this.defaults, ...opts };
    this.cb = cb;

    // Set default callback if none is defined
    if (!this.cb) {
      this.setDefaultCb();
    }
  }

  /**
   * Apply predefined alert options
   */
  setPredefinedOptions() {
    console.log(this.options.type);
    switch (this.options.type) {
      case 'ajax':
        // @todo remove and use custom alert - to specific to be a default
        Swal.fire({
          title: 'Ajax request example',
          text: this.options.text,
          icon: 'info',
          showCancelButton: true,
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
        })
          .then(() => {
            setTimeout(() => {
              Swal.fire('Ajax request finished!')
                .catch((arg) => {
                  console.log('canceled', arg);
                });
            }, 2000);
          })
          .catch((arg) => {
            console.log('canceled', arg);
          });
        break;
      case 'confirm-info':
        this.setInfoOptions();
        break;
      case 'confirm-success':
        this.setSuccessOptions();
        break;
      case 'confirm-warning':
        this.setWarningOptions();
        break;
      case 'alert-danger':
        // @todo case should be confirm-danger?
        this.setDangerOptions(true);
        break;
      case 'confirm-danger':
        // @todo remove and use custom alert - to specific to be a default
        Swal.fire({
          title: 'Are you sure?',
          text: this.options.text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-danger',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel plx!',
          closeOnConfirm: false,
          closeOnCancel: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your imaginary file has been deleted.',
                icon: 'success',
              })
                .catch((arg) => {
                  console.log('canceled', arg);
                });
            } else {
              Swal.fire({
                title: 'Cancelled!',
                text: 'Your imaginary file is safe.',
                icon: 'error',
              })
                .catch((arg) => {
                  console.log('canceled', arg);
                });
            }
          })
          .catch((arg) => {
            console.log('canceled', arg);
          });
        break;
      default:
        this.setDangerOptions();
        break;
    }
  }

  /**
   * Show dismiss reason
   */
  setDefaultCb() {
    this.cb = (result) => {
      if (result.isDismissed) {
        console.log('Dismissed: ', result.dismiss);
      }
    };
  }

  // Info
  setInfoOptions(hasConfirm = false) {
    // Default info options
    let infoOptions = {
      title: 'Are you sure?',
      icon: 'info',
      confirmButtonText: 'Info',
      customClass: {
        confirmButton: 'btn btn-lg btn-info',
      },
    };

    // Can be cancelled
    if (hasConfirm) {
      infoOptions = {
        ...infoOptions,
        ...{
          title: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'Info',
        },
      };
    }

    // Merge options
    this.options = {
      ...this.options,
      infoOptions,
    };
  }

  // Success
  setSuccessOptions(hasConfirm = false) {
    // Default confirm options
    let successOptions = {
      title: 'Success',
      icon: 'success',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-lg btn-success',
      },
    };

    // Can be cancelled
    if (hasConfirm) {
      successOptions = {
        ...successOptions,
        ...{
          title: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'Success',
        },
      };
    }

    // Merge options
    this.options = {
      ...this.options,
      successOptions,
    };
  }

  // Warning
  setWarningOptions(hasConfirm = false) {
    // Default warning options
    let warningOptions = {
      title: 'Warning',
      icon: 'warning',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-lg btn-warning',
      },
    };

    // Can be cancelled
    if (hasConfirm) {
      warningOptions = {
        ...warningOptions,
        ...{
          title: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'Warning',
        },
      };
    }

    // Merge options
    this.options = {
      ...this.options,
      ...warningOptions,
    };
  }

  // Danger
  setDangerOptions(hasConfirm = false) {
    // Default danger options
    let dangerOptions = {
      title: 'Whoops...',
      icon: 'error',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-lg btn-danger',
      },
    };

    // Can be cancelled
    if (hasConfirm) {
      dangerOptions = {
        ...dangerOptions,
        ...{
          title: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'Danger!',
        },
      };
    }

    // Merge options
    this.options = {
      ...this.options,
      ...dangerOptions,
    };
  }

  // contact webpartner
  setContactUsOptions() {
    // Default danger options
    const webatOptions = {
      title: getTranslation('contact your webpartner'),
      icon: 'error',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-lg btn-danger',
      },
    };

    // Merge options
    this.options = {
      ...this.options,
      ...webatOptions,
    };
  }

  /**
   * Show alert
   */
  show() {
    // Apply default styles
    if (this.options.type) {
      this.setPredefinedOptions();
    }

    Swal.fire(this.options).then(this.cb);
  }
}
