import {
  windowScroll, scrollFunction, topFunction, toggleMenu,
} from '@/utils/menu-functions';
import * as featherModule from 'feather-icons';

const PageUtils = (() => {
  // Clickable Menu
  if (document.getElementById('navigation')) {
    const elements = document.getElementById('navigation').getElementsByTagName('a');
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = elements.length; i < len; i++) {
      // eslint-disable-next-line func-names
      elements[i].onclick = function (elem) {
        // eslint-disable-next-line no-script-url
        if (elem.target.getAttribute('href') === 'javascript:void(0)') {
          const submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle('open');
        }
      };
    }
  }

  // active sidebar
  // eslint-disable-next-line no-restricted-globals
  const current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  if (current === '') return;
  const menuItems = document.querySelectorAll('.sidebar-nav a');
  for (let i = 0, len = menuItems.length; i < len; i++) {
    if (menuItems[i].getAttribute('href').indexOf(current) !== -1) {
      menuItems[i].parentElement.className += ' active';
    }
  }

  // scroll
  window.addEventListener('scroll', (e) => {
    e.preventDefault();
    windowScroll();
  });

  // back-to-top
  window.onscroll = function () {
    scrollFunction();
  };

  // back-to-top click event
  document.getElementById('back-to-top').addEventListener('click', (e) => {
    e.preventDefault();
    topFunction();
  });

  // toggle-menu if relevant
  const toggle = document.getElementById('isToggle');
  if (toggle) {
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      console.log('toggle menu');
      toggleMenu();
    });
  }

  // Feather icons
  featherModule.replace();
})();

export default PageUtils;
