// Designed by: Hoang Nguyen
// Original image: https://dribbble.com/shots/5919154-Tab-Bar-Label-Micro-Interaction
import { setLineWidth, handleTransition } from '@/utils/menu-functions';

const buttons = document.querySelectorAll('.menu__item');
let activeButton = document.querySelector('.menu__item.active');

buttons.forEach((item) => {
  const text = item.querySelector('.menu__text');
  // setLineWidth(text, item);

  // window.addEventListener('resize', () => {
  //   setLineWidth(text, item);
  // });

  // eslint-disable-next-line func-names
  item.addEventListener('click', function () {
    if (this.classList.contains('active')) return;

    this.classList.add('active');
    this.querySelector('.menu__text').classList.add('active');

    if (activeButton) {
      activeButton.classList.remove('active');
      activeButton.querySelector('.menu__text').classList.remove('active');
    }

    handleTransition(this, text);
    activeButton = this;
  });
});
