import { getSlaveData } from '@/js/api/api-calls';
import { getTranslation } from '@/utils/languages';
import { triggerNotification } from '@/utils/notifications';

export default class MasterSlave {
  /**
   * Constructor for the MasterSlave class
   */
  constructor(select, formEl) {
    this.dom = {
      select,
      formEl,
    };

    this.props = {
      slaveToUpdate: null,
      chainToUpdate: null,
    };

    this.events = {
      onChange: this.onChange.bind(this),
      apiCall: null,
      invokeChain: null,
    };

    // bind & inits
    this.mount();
  }

  /**
   * binding & inits
   */
  mount() {
    console.log('mount master slave');

    // set slave element
    this.props.slaveToUpdate = this.dom.select.dataset.slave;

    // set the api call
    if (this.dom.select.dataset.call) {
      this.events.apiCall = this.dom.select.dataset.call;
    }

    // add change event
    this.dom.select.addEventListener('change', this.events.onChange);
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount master slave');

    // remove submit event
    this.dom.select.removeEventListener('change', this.events.onChange);
  }

  /**
   * manage the callback after post (reloading stuff)
   * @param e {CustomEvent}
   */
  // eslint-disable-next-line class-methods-use-this
  async onChange(e) {
    console.log('master changed...');

    if (!this.events.apiCall) {
      // console.log('nothing to call...');
      return;
    }

    // form data
    const formData = new FormData();
    formData.append('slave', this.props.slaveToUpdate);
    formData.append('parentValue', e.target.value);
    formData.append('call', this.events.apiCall);
    // axias call
    await getSlaveData(formData)
      .then((response) => {
        const elSlave = this.dom.formEl.querySelector(`#${this.props.slaveToUpdate}`);
        // empty is element of response
        if (response.data.justempty) {
          console.log(elSlave.type);
          switch (elSlave.type) {
            case 'select':
              elSlave.value = 0;
              break;
            default:
              elSlave.value = '';
              break;
          }
        } else {
          // remove all current elements
          while (elSlave.options.length > 0) {
            elSlave.remove(0);
          }
          // add "make a choice" option
          const firstOption = document.createElement('option');
          firstOption.value = '';
          firstOption.text = getTranslation('Make a choice');
          elSlave.appendChild(firstOption);

          // add the other entries
          for (const [key, value] of Object.entries(response.data.apiresult)) {
            const option = document.createElement('option');
            option.value = value.dataKey;
            option.text = value.dataValue;
            elSlave.appendChild(option);
          }
        }
        // invoke a chain reaction
        this.invokeChain();
      })
      .catch((error) => {
        console.log(error);
        triggerNotification(getTranslation('something went wrong'), 'error');
      });
  }

  /**
   * custom event to invoke a chain
   */
  invokeChain() {
    this.events.invokeChain = new CustomEvent('onChainInvoked', {
      detail: {
        chainElement: this.props.slaveToUpdate,
      },
    });

    this.dom.formEl.dispatchEvent(this.events.invokeChain);
  }
}
