// [::1] is the IPv6 localhost address
const localDomains = ['localhost', '127.0.0.1', '[::1]'];

/**
 * Check if hostname is local or external
 * @param hostname {string}
 */
export function isLocal(hostname) {
  return (localDomains.includes(hostname) || !hostname.includes('.'));
}

/**
 * Get root URL
 * @returns {string}
 */
export function getRoot() {
  const { protocol, hostname, pathname } = window.location;
  const segments = pathname.split('/');

  let tempRoot = `${protocol}//${hostname}/`;

  if (isLocal(hostname)) {
    tempRoot += `${segments[1]}/`;
  }

  return tempRoot;
}

export function getLangRoot() {
  const { protocol, hostname, pathname } = window.location;
  const segments = pathname.split('/');

  let tempRoot = `${protocol}//${hostname}/${segments[1]}/`;

  if (isLocal(hostname)) {
    tempRoot += `${segments[2]}/`;
  }

  return tempRoot;
}

export function getImgRoot() {
  return `${getRoot()}dist/img/`;
}

// Alias for getRoot();
export const root = getRoot();

// Alias for getLangRoot();
export const langRoot = getLangRoot();

export const imgRoot = getImgRoot();

export default {
  root,
  langRoot,
  imgRoot,
};
