// Cookies Policy
import { on, el, someCookie } from '@/utils/functions';
import axios from 'axios';
import { langRoot } from '@/utils/paths';

(() => {
  try {
    /* popup button handler */
    on('.cookie-popup button', 'click', () => {
      el('.cookie-popup').classList.add('cookie-popup-accepted');
      document.cookie = 'cookie-accepted=true';

      // pass the necessary data
      const formData = new FormData();

      // make axios call
      axios({
        method: 'post',
        url: `${langRoot}accept-cookie`,
        data: formData,
      })
        .then((response) => {
          console.log(response);
        });
    });

    /* popup init handler */
    if (someCookie('cookie-accepted') !== 'true') {
      el('.cookie-popup').classList.add('cookie-popup-not-accepted');
    }
  } catch (error) {
    console.log(error);
  }
})();
