import axios from 'axios';
import { langRoot } from '@/utils/paths';
import MasterSlave from '@/js/components/masterslave';
import { triggerNotification } from '@/utils/notifications';

export default class QuickForm {
  /**
   * Constructor for the Quickform class
   */
  constructor(form) {
    this.dom = {
      form,
      masterSlaves: [],
    };

    this.props = {
      handle: null,
      callback: null,
      ref: null,
    };

    this.events = {
      onSubmit: this.onSubmit.bind(this),
      onChainInvoked: this.onChainInvoked.bind(this),
    };

    // bind & inits
    this.mount();
  }

  /**
   * binding & inits
   */
  mount() {
    console.log('mount quick form');

    // set the handle
    this.props.handle = this.dom.form.dataset.handle;
    this.props.callback = this.dom.form.dataset.callback;
    this.props.ref = this.dom.form.dataset.ref;

    // add submit event
    this.dom.form.addEventListener('submit', this.events.onSubmit);

    // make master slaves objects if any
    this.dom.masterSlaves = this.dom.form.querySelectorAll('[data-slave]');
    if (this.dom.masterSlaves.length > 0) {
      this.dom.masterSlaves.forEach((selectEl) => new MasterSlave(selectEl, this.dom.form));
    }

    // dispatched events
    this.dom.form.addEventListener('onChainInvoked', this.events.onChainInvoked);
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount quick form');

    // remove submit event
    this.dom.form.removeEventListener('submit', this.events.onSubmit);

    // remove chain change event
    this.dom.form.removeEventListener('onChainInvoked', this.events.onChainInvoked);
  }

  /**
   * manage the callback after post
   * @param e {CustomEvent}
   */
  onSubmit(e) {
    console.log('form submitted');

    // prevent default action
    e.preventDefault();

    // form data
    const formData = new FormData(this.dom.form);

    // make axios call
    axios({
      method: 'post',
      url: langRoot + this.props.handle,
      data: formData,
    })
      .then((response) => {
        if (response.data.success) {
          if (response.data.redirect) {
            window.location.href = response.data.redirect;
          } else if (response.data.successMessage) {
            triggerNotification(response.data.successMessage);
          }
          // reset the form if requested
          if (response.data.reset) {
            this.resetForm();
          }
        } else {
          triggerNotification(response.data.errorMessage, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        triggerNotification('Something went wrong, please contact naughty and wild?', 'error');
      });
  }

  /**
   * reset a form
   */
  resetForm() {
    // clearing inputs
    const inputs = this.dom.form.getElementsByTagName('input');

    for (let i = 0; i < inputs.length; i += 1) {
      switch (inputs[i].type) {
        case 'radio':
        case 'checkbox':
          inputs[i].checked = false;
          break;
        case 'text':
          inputs[i].value = '';
          break;
        default:
        // do nothing
      }
    }

    // clearing selects
    const selects = this.dom.form.getElementsByTagName('select');
    // loop
    for (let i = 0; i < selects.length; i += 1) {
      selects[i].selectedIndex = 0;
    }

    // clearing textarea
    const text = this.dom.form.getElementsByTagName('textarea');
    // loop
    for (let i = 0; i < text.length; i += 1) {
      text[i].value = '';
    }
  }

  /**
   * chain events invoker
   * @param e {CustomEvent}
   */
  onChainInvoked(e) {
    // console.log('chain invoked...');
    const event = new Event('change');
    const chainEl = this.dom.form.querySelector(`#${e.detail.chainElement}`);
    chainEl.dispatchEvent(event);
  }
}
