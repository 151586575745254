import { Dropzone } from 'dropzone';
import { root } from '@/utils/paths';

class ProfilePicture {
  constructor() {
    this.dom = {
      dropzone: null,
      dropzoneBtn: null,
    };

    this.events = {
      onSuccess: this.onSuccess.bind(this),
    };

    // mount the class
    this.mount();
  }

  mount() {
    console.log('mount profile-picture class...');

    const dropzoneOptions = {
      url: `${root}api/change-profile-picture`,
      paramName: 'file[0]',
      maxFiles: 1,
      uploadMultiple: false,
      createImageThumbnails: false,
      acceptedFiles: 'image/*',
      preview: false,
      autoProcessQueue: true,
    };
    this.dom.dropzone = new Dropzone('img#profile-picture', dropzoneOptions);

    // add sent event
    this.dom.dropzone.on('success', this.events.onSuccess);
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount account settings...');
  }

  /**
   * manage the callback after post
   * @param file {Object}
   */
  onSuccess(file) {
    document.getElementById('profile-picture').src = JSON.parse(file.xhr.response).avatar;
  }
}

// instantiate if relevant
if (document.getElementsByClassName('profile-picture-holder').length > 0) {
  // eslint-disable-next-line no-new
  new ProfilePicture();
}
