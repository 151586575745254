export default {
  search: 'search...',
  yes: 'yes',
  no: 'no',
  cancel: 'cancel',
  ok: 'OK',
  save: 'save',
  'are you sure': 'Are you really sure?',
  success: 'Action was successful',
  error: 'Action was not successful',
  'something went wrong': 'An error has occurred.  If the problem remains occurring, please contact the webmasters?',
  'contact your webpartner': 'An error has occurred, please contact the webmasters?',
  'Drop files here to upload': 'Drop your files here',
  'Your browser does not support drag drop file uploads': 'Je browser ondersteunt geen drag & drop!',
  'Please use the fallback': 'Gebruik de uploadknop hieronder aub?',
  'File is too big': 'Bestand is te groot  ({{filesize}}MiB). Maximum grootte: {{maxFilesize}}MiB.',
  'Type not supported': 'Dit type bestanden is niet toegestaan.',
  'Server responded with statusCode': 'Server antwoordde met statuscode {{statusCode}}.',
  'Cancel upload': 'Opladen annuleren',
  'Upload canceled': 'Het opladen werd geannuleerd.',
  'Are you sure you want to cancel this upload': 'Ben je zeker dat je wil annuleren?',
  'Remove file': 'Verwijder bestand',
  'You can not upload any more files': 'Je kan geen bestanden meer opladen.',
  'Files ready to move': ' Files are ready to move',
  'Files ready to copy': ' Files are ready to copy',
  'No files selected': ' No files were selected',
  Datastore: 'Datastore',
  'Name new folder': 'What\'s the name for the new folder please?',
  'New name folder': 'What\'s the new name for the folder please?',
  'New name file': 'What\'s the new name for the file please?',
  'Almost delete': 'You are ready to delete...',
  Positionchange: 'Change position',
  Positionchanged: 'The position was changed',
  NoneSelected: 'No items selected',
  AllSelected: 'All selected',
  'Make a choice': 'Make a choice',
  'Delete item': 'Delete item',
  Valuechange: 'Change value',
  'Add category': 'Add category',
  'Required zones': 'Please fill out the required fields',
  GridSearchLabel: 'Search',
  GridSearchDefaultLabel: 'Type something...',
  GridFilterCategoriesDefaultOption: 'All categories',
  publish: 'publish item',
  unpublish: 'unpublish item',
  attention: 'attention',
};
