import axios from 'axios';
import { root } from '@/utils/paths';

/**
 * Get slave field data
 * @param data {FormData}
 * @returns {Promise}
 */
export function getSlaveData(data) {
  const callToMake = `${root}api/${data.get('call')}`;
  return axios({
    method: 'post',
    url: `${callToMake}`,
    data,
  });
}

export default {
  getSlaveData,
};
