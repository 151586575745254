// eslint-disable-next-line import/no-named-as-default
import XhrExe from '@/js/engine/xhrexe';

class ProjectXhr {
  constructor() {
    this.dom = {
      xhrActions: [],
    };
    // mount class
    this.mount();
  }

  mount() {
    console.log('mount ProjectXhr class...');
    this.dom.xhrActions = document.querySelectorAll('.xhr-action');
    // make xhr exe objects if any
    if (this.dom.xhrActions.length > 0) {
      this.dom.xhrActions.forEach((htmlEl) => new XhrExe(htmlEl));
    }
  }
}

// instantiate if relevant
// eslint-disable-next-line no-new
new ProjectXhr();
