class AccountSettings {
  constructor() {
    this.dom = {};

    this.events = {};

    // mount the class
    this.mount();
  }

  mount() {
    console.log('mount account-settings class...');
  }

  /**
   * unbind & destroy
   */
  unmount() {
    console.log('unmount account settings...');
  }
}

// instantiate if relevant
if (document.getElementById('account-settings')) {
  // eslint-disable-next-line no-new
  new AccountSettings();
}
