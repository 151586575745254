import { tns } from 'tiny-slider';

class Home {
  constructor() {
    this.dom = {
      swiperCategories: [],
      tns: null,
    };
    // mount the class
    this.mount();
  }

  mount() {
    console.log('mount home class...');
    // check for swiper categories
    this.dom.swiperCategories = document.querySelectorAll('.swiper-categories');

    // tiny slider
    if (document.getElementsByClassName('tiny-three-item').length > 0) {
      // eslint-disable-next-line no-new,new-cap
      this.dom.tns = new tns({
        container: '.tiny-three-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: 'bottom',
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 3,
          },

          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      });
    }
  }
}

// instantiate if relevant
if (document.getElementById('website-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
